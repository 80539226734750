<template>
  <div>
    <div class="mx-auto max-w-6xl">
      <div class="px-4 md:px-6">
        <slot />
      </div>
    </div>
    <DevBar />
    <DialogModal
      :title="
        $t('prescription.inactive_logout_title', { inactiveWarningMinutes })
      "
      :message="$t('prescription.inactive_logout_text', { idleTimeLeft })"
      :cancel-button-text="$t('prescription.confirm_logout_cancel')"
      :confirm-button-text="$t('prescription.confirm_logout_confirm')"
      :is-visible="showInactiveWarning"
      @cancel="cancelIdleWarning"
      @confirm="prescriptionStore.logout()"
    />
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/stores/cart';
import { usePrescriptionStore } from '~/stores/prescription';

const cartStore = useCartStore();
const prescriptionStore = usePrescriptionStore();
const route = useRoute();
const nuxtApp = useNuxtApp();
const gtm = useGtm();

const { locale } = useI18n();
const { prescriptionLocales } = useRuntimeConfig().public.featureFlags;

const {
  cancelIdleWarning,
  inactiveWarningMinutes,
  idleTimeLeft,
  showInactiveWarning,
} = useBankIdSessionExpiration();

useHead({
  ...{
    bodyAttrs: {
      class: 'bg-background-extra-light md:bg-background-light',
    },
  },
});

onMounted(async () => {
  const routeName = route?.name?.toString();
  if (
    !routeName?.includes('receipt') &&
    !routeName?.includes('recommended-products')
  ) {
    // Do not refetch cart on receipt page since this causes the finished cart to be restored in localstorage
    // Skip fetching on recommended products page since we want to be able to await it there

    await waitForEcom2(!!prescriptionLocales.includes(locale.value));
    await cartStore.getCart();
  }
});

watch(
  () => route.fullPath,
  () => {
    if (process.client) {
      const routeBaseName = nuxtApp.$getRouteBaseName(route) || '';
      if (!excludedRoutesForPageViews.includes(routeBaseName)) {
        gtm?.trackEvent(pageViewEvent());
      }
    }
  },
  { immediate: true }
);
</script>

<style lang="postcss">
body {
  -webkit-perspective: 1000;
  perspective: 1000;
}
</style>
